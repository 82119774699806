<template>
  <span>
    <v-app id="inspire">
      <v-content id="content">
        <v-container class="fill-height" fluid>
          <v-row>
            <v-col cols="12" align="center" justify="center">
              <p class="display-4">404</p>
            </v-col>
          </v-row>
        </v-container>
      </v-content>
    </v-app>
  </span>
</template>

<script>
export default {
  data: () => ({
    drawer: null
  })
};
</script>
